import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./lisec-app/lisec-app.module').then((m) => m.LisecAppModule),
  },
  {
    path: 'styleguide',
    title: 'Styleguide',
    loadChildren: () =>
      import('./styleguide/styleguide.module').then((m) => m.StyleguideModule),
  },
  {
    path: 'storyblok-test',
    title: 'Storyblok Test',
    loadChildren: () =>
      import('./storyblok-test/storyblok-test.module').then(
        (m) => m.StoryblokTestModule
      ),
  },
  {
    path: 'preview',
    loadChildren: () =>
      import('./preview/preview.module').then((m) => m.PreviewModule),
  },
  {
    path: 'token-generator',
    loadChildren: () =>
      import('./token-generator/token-generator.module').then(
        (m) => m.TokenGeneratorModule
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
